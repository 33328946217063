<template>
  <app-module-view>
    <template slot="buttons">
      <router-link
        tag="button"
        class="btn btn btn-secondary"
        :to="{ name: 'printPublication_list' }"
      >
        <i class="far fa-newspaper"></i> {{ $t("printPublication.buttons.back_to_list") }}
      </router-link>
    </template>
    <template slot="body">
      <div v-if="dataLoaded" class="row print-preview-list">
        <div
          class="col-md-4 col-sm-6 col-lg-3"
          v-for="(item, index) in printTitles"
          :key="`item-${index}`"
        >
          <div class="card">
            <div class="card-body">
              <div class="item text-center">
                <div
                  class="image"
                  :style="'background-image: url(' + getCoversImagePath(item.name) + ')'"
                ></div>
                <small data-v-e9fbb4e6="" class="text-muted">{{ item.title }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-preloader v-else></app-preloader>
      <div class="col-12 text-center text-muted">
        <p>{{ $t("printPublication.current.info") }}</p>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import PrintTitleService from '../../services/print/PrintTitleService'

export default {
  name: 'PrintPublicationList',
  data () {
    return {
      dataLoaded: false,
      items: []
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    printTitles () {
      return this.$store.getters['printTitle/all']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader
  },
  methods: {
    getPrintTitles () {
      this.$store.dispatch('printTitle/fetchAll')
        .then(() => {
          this.dataLoaded = true
        })
    },
    getCoversImagePath (name) {
      return PrintTitleService.getImagePath(name)
    }
  },
  mounted () {
    this.getPrintTitles()
  }
}
</script>

<style lang="scss" scoped>
.print-preview-list {
  .image {
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    border: none;
    position: relative;
    margin-top: 1px;
  }
}
</style>
